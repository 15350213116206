<template>
  <el-container class="email-detail">
    <el-header>
      <div class="header-title" @click="showEmailList">
        <i class="el-icon-back"></i> 查看发票
      </div>
    </el-header>

    <el-main>
      <div class="email-detail-content">
        <div class="email-content">
          <el-image
            style="width: 100%"
            :src="invoiceInfo.invoiceImage"
          >
            <div slot="error" class="image-slot">
              <i style="font-size:500px;color: #F2F6FC" class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="create-invoice-1">
          <h4>基础信息</h4>
          <div class="create-cell">
            <div class="create-cell-title">所属分类</div>
            <div class="create-cell-content">
              {{ invoiceInfo.type }}
            </div>
          </div>
          <div class="create-cell">
            <div class="create-cell-title">logo</div>
            <div class="create-cell-content">
              <el-image
                style="width: 80px; height: 80px"
                :src="invoiceInfo.icon"
              >
                <div slot="error" class="image-slot">
                  <i style="font-size:80px;color: #F2F6FC" class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <div class="create-cell">
            <div class="create-cell-title">主题色</div>
            <div class="create-cell-content">
              <span>{{ invoiceInfo.iconDominantColor }}</span>
              <span
                :style="{
                                    backgroundColor:
                                        invoiceInfo.iconDominantColor,
                                }"
                style="
                                    display: inline-block;
                                    margin-left: 16px;
                                    height: 12px;
                                    line-height: 12px;
                                    width: 30px;
                                "
              ></span>
            </div>
          </div>
          <span class="hr-span"></span>
          <div v-if="invoiceInfo.statusBtn === 'compile'">
            <h4>发票信息</h4>
            <div class="create-cell">
              <span class="pre-icon">*</span>
              <div class="create-cell-title">
                价税合计
              </div>
              <div class="create-cell-content">
                <el-input
                  maxlength="100"
                  type="number"
                  size="mini"
                  v-model="invoiceInfo.amount"
                  placeholder="请输入价税合计"
                ></el-input>
              </div>
            </div>
            <h5>销售方</h5>
            <div class="create-invoice-cell">
              <div class="create-cell">
                <span :style="{ visibility: isElectronicInvoiceCategory ? 'hidden' : 'visible' }" class="pre-icon">*</span>
                <div class="create-cell-title">
                  销方名称
                </div>
                <div class="create-cell-content">
                  <el-input
                    size="mini"
                    maxlength="100"
                    type="text"
                    placeholder="请输入销方名称"
                    v-model.trim="invoiceInfo.payee"
                  ></el-input>
                </div>
              </div>
              <div class="create-cell">
                <span style="color: white" class="pre-icon">*</span>
                <div class="create-cell-title">销方缩写</div>
                <div class="create-cell-content">
                  <el-input
                    size="mini"
                    maxlength="100"
                    type="text"
                    placeholder="请输入销方缩写"
                    disabled
                    v-model="invoiceInfo.payeeShortName"
                  ></el-input>
                </div>
              </div>
              <div class="create-cell">
                <span :style="{ visibility: isElectronicInvoiceCategory ? 'hidden' : 'visible' }" class="pre-icon">*</span>
                <div class="create-cell-title">
                  纳税人识别号
                </div>
                <div class="create-cell-content">
                  <el-input
                    maxlength="100"
                    type="text"
                    size="mini"
                    placeholder="请输入纳税人识别号"
                    v-model.trim="invoiceInfo.payeeTaxNo"
                  ></el-input>
                </div>
              </div>
              <div class="create-cell">
                <span style="color: white" class="pre-icon">*</span>
                <div class="create-cell-title">
                  开户行及账号
                </div>
                <div class="create-cell-content">
                  <el-input
                    maxlength="100"
                    type="text"
                    size="mini"
                    placeholder="请输入开户行及账号"
                    disabled
                    v-model="invoiceInfo.payeeBankInfo"
                  ></el-input>
                </div>
              </div>
              <div class="create-cell">
                <span style="color: white" class="pre-icon">*</span>
                <div class="create-cell-title">
                  地址、电话
                </div>
                <div class="create-cell-content">
                  <el-input
                    maxlength="100"
                    type="textarea"
                    size="mini"
                    placeholder="请输入地址、电话"
                    disabled
                    show-word-limit
                    v-model="invoiceInfo.payeeAddress"
                  ></el-input>
                </div>
              </div>
            </div>
            <h5>购买方</h5>
            <div class="create-cell">
              <span class="pre-icon">*</span>
              <div class="create-cell-title">
                购方名称
              </div>
              <div class="create-cell-content">
                <el-input
                  size="mini"
                  maxlength="100"
                  type="text"
                  placeholder="请输入购方名称"
                  v-model.trim="invoiceInfo.payer"
                ></el-input>
              </div>
            </div>
            <div class="create-cell">
              <span style="color: white" class="pre-icon">*</span>
              <div class="create-cell-title">购方缩写</div>
              <div class="create-cell-content">
                <el-input
                  size="mini"
                  maxlength="100"
                  type="text"
                  placeholder="请输入纳税人识别号"
                  disabled
                  v-model="invoiceInfo.payerShortName"
                ></el-input>
              </div>
            </div>
            <div class="create-cell">
              <span class="pre-icon">*</span>
              <div class="create-cell-title">
                纳税人识别号
              </div>
              <div class="create-cell-content">
                <el-input
                  size="mini"
                  maxlength="100"
                  type="text"
                  placeholder="请输入纳税人识别号"
                  v-model.trim="invoiceInfo.payerTaxNo"
                ></el-input>
              </div>
            </div>
            <div class="create-cell">
              <span style="color: white" class="pre-icon">*</span>
              <div class="create-cell-title">
                开户行及账号
              </div>
              <div class="create-cell-content">
                <el-input
                  size="mini"
                  maxlength="100"
                  type="text"
                  placeholder="请输入开户行及账号"
                  disabled
                  v-model="invoiceInfo.payerBankInfo"
                ></el-input>
              </div>
            </div>
            <div class="create-cell">
              <span style="color: white" class="pre-icon">*</span>
              <div class="create-cell-title">
                地址、电话
              </div>
              <div class="create-cell-content">
                <el-input
                  size="mini"
                  maxlength="100"
                  type="textarea"
                  placeholder="请输入地址、电话"
                  show-word-limit
                  disabled
                  v-model="invoiceInfo.payerAddress"
                ></el-input>
              </div>
            </div>
            <div class="create-btn">
              <el-button
                @click="handleSave"
                type="primary"
                size="mini"
              >保存</el-button>
            </div>
          </div>
          <div v-else>
            <h4>发票信息</h4>
            <div class="create-cell">
              <div class="create-cell-title">价税合计</div>
              <div class="create-cell-content">
                ¥{{ invoiceInfo.amount }}
              </div>
            </div>
            <h5>销售方</h5>
            <div class="create-cell">
              <div class="create-cell-title">销方名称</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payee }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">销方缩写</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payeeShortName }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">纳税人识别号</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payeeTaxNo }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">地址、电话</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payeeAddress }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">开户行及账号</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payeeBankInfo }}
              </div>
            </div>
            <h5>购买方</h5>
            <div class="create-cell">
              <div class="create-cell-title">购方名称</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payer }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">购方缩写</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payerShortName }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">纳税人识别号</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payerTaxNo }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">地址、电话</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payerAddress }}
              </div>
            </div>
            <div class="create-cell">
              <div class="create-cell-title">开户行及账号</div>
              <div class="create-cell-content">
                {{ invoiceInfo.payerBankInfo }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    props: ["invoiceInfo"],
    components: {},
    data() {
      return {
        color: "#FFDD2F",
        invoiceCode: "",
        invoiceNum: "",
        invoiceDate: "",
        invoiceAccount: "",
        fileList: [
          {
            name: "food.jpeg",
            url:
              "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
          {
            name: "food2.jpeg",
            url:
              "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
        ],
      };
    },
    computed: {
      isElectronicInvoiceCategory() {
        return ['ELECTRONIC_TRAIN_INVOICE','ELECTRONIC_AIRCRAFT_INVOICE'].includes(this.invoiceInfo.invoiceCategory);
      },
    },
    methods: {
      handleSave() {
        const {id, amount, payee, payeeTaxNo, payer, payerTaxNo} = this.invoiceInfo
        this.verify() &&
        this.FetchPost(
          this.requestUrl.invoice.invoiceSave,
          {
            id, amount, payee, payeeTaxNo, payer, payerTaxNo
          }
        ).then((res) => {
          if (res.errno === "0") {
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.errmsg);
          }
        })
          .catch((err) => {
            this.$message(err);
          });
      },
      verify() {
        if(!this.invoiceInfo.amount) {
          this.$message.warning("价税合计不能为空");
          return false;
        }
        if(!this.invoiceInfo.payee && !this.isElectronicInvoiceCategory) {
          this.$message.warning("销售方名称不能为空");
          return false;
        }
        if(!this.invoiceInfo.payeeTaxNo && !this.isElectronicInvoiceCategory) {
          this.$message.warning("销售方纳税人识别号不能为空");
          return false;
        }
        if(!this.invoiceInfo.payer) {
          this.$message.warning("购买方名称不能为空");
          return false;
        }
        if(!this.invoiceInfo.payerTaxNo) {
          this.$message.warning("购买方纳税人识别号不能为空");
          return false;
        }
        return true
      },
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      showEmailList() {
        this.$emit("switchEmailVisible", {type: "list", info: {}});
      },
    },
    mounted() {
    },
  };
</script>

<style lang="less" scoped>
  .email-detail {
    .header-title {
      cursor: pointer;
    }
    .email-detail-content {
      display: flex;
      justify-content: space-between;
      .email-content {
        flex: auto;
        height: calc(100vh - 130px);
        overflow: auto;
        background-color: white;
        padding: 16px;
        margin-right: 16px;
        text-align: center;
      }
      .create-invoice-1 {
        height: calc(100vh - 130px);
        overflow: auto;
        flex: none;
        background-color: white;
        padding: 16px;
        width: 370px !important;
        .hr-span {
          margin: 16px 0;
          display: block;
          border-top: dashed 1px lightgrey;
        }
        h4 {
          text-align: center;
        }
        .create-cell {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12px;
          .create-cell-content {
            flex: auto;
            .el-input__inner {
              width: 100% !important;
            }
          }

          .pre-icon {
            color: red;
          }
          .create-cell-title {
            flex: none;
            width: 100px;
          }
        }
        .create-btn {
          margin-top: 32px;
          text-align: center;
        }
      }
    }
  }
</style>
